import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterDefault from "../../components/Elements/Counter/CounterDefault";

import contactHereImg from "../../assets/img/about/contact-here.png";

const CallUs = () => {
  return (
    <div className="rs-call-us relative pt-120 md-pt-70 md-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hidden-md">
            <div className="image-part">
              <img src={contactHereImg} alt="" />
            </div>
          </div>
          <div className="col-lg-6 pb-120 md-pb-80">
            <div className="rs-contact-box text-center">
              <div className="address-item mb-26">
                <div className="address-icon">
                  <i className="fa fa-phone"></i>
                </div>
              </div>
              <SectionTitle
                sectionClass="sec-title3 text-center"
                subtitleClass="sub-text"
                subtitle=""
                titleClass="title"
                title="(+33) 654-1622"
                descClass="desc"
                description="Vous avez une idée ou un projet en tête, appelez-nous ou prenez rendez-vous. Notre représentant vous répondra sous peu."
              />
              <div className="btn-part mt-40">
                <Link
                  className="readon lets-talk"
                  to={{
                    pathname: "https://calendly.com/rochdi-berrahmouni/30min",
                  }}
                  target="_blank"
                >
                  Parlons-en !
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area start */}
      <CounterDefault />
      {/* counter area end */}
    </div>
  );
};

export default CallUs;
