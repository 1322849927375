import React from "react";
import { Link } from "react-router-dom";
import CTA from "../../components/Common/CTA";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleService from "../../components/Service/SingleService";
import PricingMain from "../Pricing";
import SingleProcess from "../../components/Common/Process";

// Service Icons
import serviceIcon2 from "../../assets/img/service/icons/2.png";
import serviceIcon4 from "../../assets/img/service/icons/4.png";
import serviceIcon5 from "../../assets/img/service/icons/5.png";

const ServiceThreeMain = () => {
  return (
    <React.Fragment>
      {/* services-area-start */}
      <div className="rs-services style4 modify1 services3 gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon2}
                Title="Development Web"
                Text="Services de développement d'applications Web et cloud pour augmenter l'évolutivité, l'élasticité et la disponibilité des logiciels."
              />
            </div>
            <div className="col-lg-4 col-md-6 md-mb-20">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon4}
                Title="Clould & DevOps"
                Text="Notre équipe fournit des services d'intégration (CI) et de livraison continues (CD) de logiciels grace à notre maitrise de l'IaC"
              />
            </div>
            <div className="col-lg-4 col-md-6 sm-mb-20">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon5}
                Title="Product & Design"
                Text="Nos experts créent des conceptions UI/UX utiles et utilisables pour les logiciels offrent une meilleure accessibilité."
              />
            </div>
          </div>
        </div>
      </div>
      {/* services-area-end */}

      {/* process-area-start */}
      <div className="rs-process style5 bg5 pt-120 pb-120 md-pt-80 md-pb-64">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 md-mb-40">
              <div className="process-wrap">
                <SectionTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text new"
                  subtitle="Notre Processus"
                  titleClass="title white-color"
                  title="Notre processus de travail - Comment nous travaillons pour nos clients"
                />
                <div className="btn-part mt-40">
                  <Link to="/contact">
                    <a className="readon learn-more contact-us">
                      Contactez nous
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8 pl-30 md-pl-15">
              <div className="row">
                <div className="col-md-6 mb-40">
                  <SingleProcess
                    processNumber="1"
                    processTitle="Définir"
                    processText="Identification et définition du besoin ou du problème à traiter"
                  />
                </div>
                <div className="col-md-6 mb-40">
                  <SingleProcess
                    processNumber="2"
                    processTitle="Mesurer"
                    processText="Collecter les données représentatives, mesurer la performance, identifier les zones de progrès"
                  />
                </div>
                <div className="col-md-6 sm-mb-40">
                  <SingleProcess
                    processNumber="3"
                    processTitle="Analyser"
                    processText="Une analyse approfondie des données collectées est effectuée pour comprendre les besoins"
                  />
                </div>
                <div className="col-md-6">
                  <SingleProcess
                    processNumber="4"
                    processTitle="Améliorer"
                    processText="Proposition de solutions pour améliorer le processus via la mise en œuvre de meilleures pratiques, l'optimisation et la simplification du processus"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* process-area-End */}

      {/* Pricing Section Start */}
      <PricingMain />
      {/* Pricing Section End */}

      {/* newsletter-area-start */}
      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
        ctaTitleClass="epx-title"
        ctaTitle="Développez votre entreprise et transformer votre metier avec nous."
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Entrer en contact"
      />
      {/* newsletter-area-end */}
    </React.Fragment>
  );
};

export default ServiceThreeMain;
