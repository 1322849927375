import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import ProgressBar from "../../components/Elements/Progress/ProgressBar";

import aboutPart1 from "../../assets/img/about/about-part-1.png";
import aboutPart2 from "../../assets/img/about/about-part-1-2.png";
import aboutPart3 from "../../assets/img/about/about-part-1-3.png";

const About = (props) => {
  return (
    <div
      id="rs-about"
      className="rs-about pt-130 pb-190 md-pt-80 md-pb-80 sm-pt-0"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="img-wrap">
              <img className="main" src={aboutPart1} alt="" />
              <div className="ly1">
                <img className="dance" src={aboutPart2} alt="" />
              </div>
              <div className="ly2">
                <img className="dance2" src={aboutPart3} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 pl-40 md-pl-14 md-pt-240 sm-pt-66">
            {/* Section Title Start */}
            <SectionTitle
              sectionClass="sec-title mb-30"
              subtitleClass="sub-text"
              subtitle=""
              titleClass="title pb-20"
              title="Nous vous accompagnerons vers la réussite de votre entreprise grâce à la technologie."
              descClass="desc pr-90 md-pr-0"
              description="Plus de 15 ans de travail dans les services informatiques développant des applications logicielles et des applications mobiles pour des clients du monde entier.."
            />
            {/* Section Title End */}
            <div className="row">
              <div className="col-lg-10">
                {/* Skill Bar Start */}
                <div className="rs-skillbar style1">
                  <ProgressBar
                    completed={92}
                    height={7}
                    boxbg={"#eeeeee"}
                    figurebg={"#f00"}
                    ProgressBox="progress-box"
                    ProgressFigure="progress-figure"
                    ProgressCount="Progress-count"
                    ProgressLabel="progress-label"
                    label="Architecture"
                    ProgressParent="main-div"
                  />
                  <ProgressBar
                    completed={80}
                    height={7}
                    boxbg={"#eeeeee"}
                    figurebg={"#f00"}
                    ProgressBox="progress-box"
                    ProgressFigure="progress-figure"
                    ProgressCount="Progress-count"
                    ProgressLabel="progress-label"
                    label="Software Development"
                    ProgressParent="main-div"
                  />
                  <ProgressBar
                    completed={95}
                    height={7}
                    boxbg={"#eeeeee"}
                    figurebg={"#f00"}
                    ProgressBox="progress-box"
                    ProgressFigure="progress-figure"
                    ProgressCount="Progress-count"
                    ProgressLabel="progress-label"
                    label="Web Development"
                    ProgressParent="main-div"
                  />
                  <ProgressBar
                    completed={78}
                    height={7}
                    boxbg={"#eeeeee"}
                    figurebg={"#f00"}
                    ProgressBox="progress-box"
                    ProgressFigure="progress-figure"
                    ProgressCount="Progress-count"
                    ProgressLabel="progress-label"
                    label="Quality engineer"
                    ProgressParent="main-div"
                  />
                </div>
                {/* Skill Bar End */}
                <div className="btn-part mt-54">
                  <Link to="/culture" className="readon learn-more">
                    Lire plus
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
