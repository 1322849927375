import React from "react";
import { Link } from "react-router-dom";

// Recent Post Thumb Images
import thumb1 from "../../assets/img/blog/inner/1.jpg";
import thumb2 from "../../assets/img/blog/inner/2.jpg";
import thumb3 from "../../assets/img/blog/inner/3.jpg";

const RecentPostWidget = () => {
  return (
    <div className="recent-posts mb-50">
      <div className="widget-title">
        <h3 className="title">Latest Posts</h3>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to="/blog/blog-details">
            <img src={thumb1} alt="" />
          </Link>
        </div>
        <div className="post-desc">
          <Link to="/blog/blog-details">
            Refactoriser un monolithe en microservices{" "}
          </Link>
          <span className="date">
            <i className="fa fa-calendar"></i>
            January 21, 2020
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to="/blog/blog-details">
            <img src={thumb2} alt="" />
          </Link>
        </div>
        <div className="post-desc">
          <Link to="/blog/blog-details">
            11 Microservice Design Patterns and Principales
          </Link>
          <span className="date">
            <i className="fa fa-calendar"></i>
            January 21, 2020
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to="/blog/blog-details">
            <img src={thumb3} alt="" />
          </Link>
        </div>
        <div className="post-desc">
          <Link to="/blog/blog-details">
            Comment Protéger sa vie privée avec des outils livres et opensource{" "}
          </Link>
          <span className="date">
            <i className="fa fa-calendar"></i>
            January 21, 2020
          </span>
        </div>
      </div>
    </div>
  );
};

export default RecentPostWidget;
