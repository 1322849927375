import React from "react";
import RecentPostWidget from "../Widget/RecentPostWidget";
import CategoriesWidget from "../Widget/CategoriesWidget";

import SinglePost from "./SinglePost";

import blogImg2 from "../../assets/img/blog/inner/2.jpg";
import blogImg3 from "../../assets/img/blog/inner/3.jpg";
import blogImg11 from "../../assets/img/blog/inner/11.jpg";

const BlogMain = () => {
  return (
    <div className="rs-blog rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 order-last">
            <div className="widget-area">
              {/* <SearchWidget /> */}
              <RecentPostWidget />
              <CategoriesWidget />
            </div>
          </div>

          <div className="col-lg-8 pr-34 md-pr-14">
            <SinglePost
              blogImage={blogImg11}
              blogCategory="Architecture"
              blogPublishedDate="01 juin 2023"
              blogTitle="La différence entre API Keys et OAuth"
              blogDesc="L'API Key et OAuth2 sont tous deux utilisés pour gérer l'accès aux API, mais ils diffèrent dans leur mode de fonctionnement et leur niveau de sécurité."
              blogButtonClass="blog-button inner-blog"
            />
            <SinglePost
              blogImage={blogImg11}
              blogCategory="Architecture"
              blogPublishedDate="22 Novembre 2022"
              blogTitle="Refactoriser un monolithe en microservices"
              blogDesc="Migrer une application monolithique vers des microservices à l’aide d’une conception pilotée par domaine..."
              blogButtonClass="blog-button inner-blog"
            />
            <SinglePost
              blogImage={blogImg2}
              blogCategory="Architecture"
              blogPublishedDate="1 Decembre, 2022"
              blogTitle="11 Microservice Design Patterns and Principales"
              blogDesc="API Gateway, Event Sourcing, CQRS, Saga, BFF, Circuit Breaker, Strangler, Datebase per Micoservice, Externalized Configuration, Consumer - Driven Contract Tracing ..."
              blogButtonClass="blog-button inner-blog"
            />
            <SinglePost
              blogImage={blogImg3}
              blogCategory="RGPD"
              blogPublishedDate="1 Decembre 2022"
              blogTitle="Comment Protéger sa vie privée avec des outils livres et opensource"
              blogDesc="..."
              blogButtonClass="blog-button inner-blog"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogMain;
