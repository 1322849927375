import React from "react";

import SectionTitle from "../../components/Common/SectionTitle";

// Working Process Images

// Working Process Images
import aboutImg3 from "../../assets/img/bg/engagement-nyxo.png";
import aboutImg1 from "../../assets/img/bg/nyxo-engagement-1.png";
import aboutImg2 from "../../assets/img/bg/journeafrique.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Pricing Image
import effectImg1 from "../../assets/img/about/dotted-3.png";
import effectImg2 from "../../assets/img/about/shape3.png";

const EngagementMain = () => {
  return (
    <React.Fragment>
      {/* about-area-start */}
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg3} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle=""
                  titleClass="title pb-36"
                  title="Nyxo s'engage"
                  descClass="desc pb-34"
                  description="l’ensemble des acteurs du domaine de la technologie numérique, portent une lourde responsabilité. Cela s'explique à la fois par le fait que notre secteur est celui dont l'impact environnemental connaît la croissance la plus rapide et par notre capacité financière, intellectuelle et humaine à intervenir.
                  Notre mission fondamentale consiste à accompagner nos clients dans la satisfaction de leurs besoins essentiels en matière de services numériques, et ce, en privilégiant une approche rationnelle et durable.
                  "
                  secondDescClass="margin-0 pb-16"
                  secondDescription="Les défis qui se dressent devant nous sont de taille historique, et les attentes sont élevées. Cependant, Nyxo solutions se tiens prêt à les relever, déterminés à jouer pleinement notre rôle dans la transformation pour un monde meilleur."
                />
                {/* Section Title End */}
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
      </div>
      {/* about-area-end */}
      {/* Notre raison d'être-area-start */}
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="Environnement"
                  titleClass="title pb-36"
                  title="Une transformation numérique respectueuse de notre planète"
                  descClass="desc pb-34"
                  description="Nous nous engageons à adopter une approche respectueuse de l'environnement et de pratiques durables, en intégrant les principes de Green IT dans toutes nos activités. Nous nous efforçons de réduire notre empreinte carbone grâce à des initiatives telles que la réduction de la consommation énergétique de nos infrastructures, l'utilisation de technologies éco-responsables et l'optimisation de nos processus pour minimiser les déchets électroniques."
                  secondDescClass="margin-0 pb-16"
                  secondDescription="De plus, nous encourageons nos collaborateurs à adopter des comportements éco-conscients et à sensibiliser nos clients aux enjeux environnementaux, afin de favoriser une transformation numérique respectueuse de notre planète. Notre engagement envers un avenir durable est au cœur de nos valeurs et influence toutes nos décisions et actions en tant que société technologique"
                />
                {/* Section Title End */}
              </div>
            </div>
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Notre raison d'être-area-end */}
      {/* about-area-start */}
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg2} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="Social"
                  titleClass="title pb-36"
                  title="Nyxo for Africa"
                  descClass="desc pb-34"
                  description="Nous nous engageons à avoir un impact positif et durable pour la génération future"
                  secondDescClass="margin-0 pb-16"
                  secondDescription="Parrainage d'écoles dans des zones rurales en Afrique : Nous apporterons notre soutien aux écoles dans les régions rurales d'Afrique en fournissant des ressources éducatives, des équipements informatiques et en contribuant à l'amélioration des infrastructures éducatives. Nous croyons en l'égalité des chances en matière d'éducation et œuvrerons à créer des opportunités pour les enfants, leur offrant un accès égal à une éducation de qualité."
                />
                {/* Section Title End */}
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
      </div>

      {/* Team Area Start @TODO 
      <div
        id="rs-team"
        className="rs-team pt-120 pb-120 md-pt-80 md-pb-80 xs-pb-54"
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg white-color"
            subtitle="Notre équipe"
            titleClass="title white-color"
            title="Expert IT Consultants"
          />
          <OwlCarousel options={options}>
            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamImg1}
              Title="Makhaia"
              Designation="Business Analyst"
              Linkedin="https://www.linkedin.com/in/rochdi-berrahmouni-09308712/"
              Twitter="https://twitter.com/RBerrahmouni"
              Calendly="https://calendly.com/rochdi-berrahmouni"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamImg2}
              Title="Rochdi"
              Designation="Founder & Architecte solutions"
              Linkedin="https://www.linkedin.com/in/rochdi-berrahmouni-09308712/"
              Twitter="https://twitter.com/RBerrahmouni"
              Calendly="https://calendly.com/rochdi-berrahmouni"
            />

            <SingleTeam
              itemClass="team-item-wrap"
              teamImage={teamImg3}
              Title="Karl"
              Designation="Expert IAM"
              Linkedin="https://www.linkedin.com/in/rochdi-berrahmouni-09308712/"
              Twitter="https://twitter.com/RBerrahmouni"
              Calendly="https://calendly.com/rochdi-berrahmouni"
            />
          </OwlCarousel>
        </div>
      </div>
    */}
      {/* Team Area End */}

      {/* pricing-area-start */}
      {/*
      <div className="rs-pricing style2 gray-bg pt-120 pb-144 md-pt-80 md-pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Pricing"
            titleClass="title title2"
            title="Our Pricing Plan"
          />
          <div className="row">
            <div className="col-lg-4 md-mb-30">
              <SinglePricing
                itemClass="pricing-table new-style2"
                badge="Silver"
                pricingImage={silverImg}
                currency="$"
                price="29.99"
                period="Monthly Package"
                buttonClass="readon buy-now"
                buttonText="Buy Now"
                buttonURL="#"
                // Feature List (1 to 4 item)
                featureText1="Powerful Admin Panel"
                ability1="yes"
                featureText2="1 Native Android App"
                ability2="yes"
                featureText3="Multi-Language Support"
                ability3="no"
                featureText4="Support via E-mail and Phone"
                ability4="no"
              />
            </div>

            <div className="col-lg-4 md-mb-30">
              <SinglePricing
                itemClass="pricing-table primary-bg"
                badge="Gold"
                pricingImage={goldImg}
                currency="$"
                price="39.99"
                period="Monthly Package"
                buttonClass="readon buy-now"
                buttonText="Buy Now"
                buttonURL="#"
                // Feature List (1 to 4 item)
                featureText1="Powerful Admin Panel"
                ability1="yes"
                featureText2="1 Native Android App"
                ability2="yes"
                featureText3="Multi-Language Support"
                ability3="yes"
                featureText4="Support via E-mail and Phone"
                ability4="no"
              />
            </div>

            <div className="col-lg-4">
              <SinglePricing
                itemClass="pricing-table new-style2"
                badge="Platinum"
                pricingImage={platinumImg}
                currency="$"
                price="79.99"
                period="Monthly Package"
                buttonClass="readon buy-now"
                buttonText="Buy Now"
                buttonURL="#"
                // Feature List (1 to 4 item)
                featureText1="Powerful Admin Panel"
                ability1="yes"
                featureText2="1 Native Android App"
                ability2="yes"
                featureText3="Multi-Language Support"
                ability3="yes"
                featureText4="Support via E-mail and Phone"
                ability4="yes"
              />
            </div>
          </div>
        </div>
      </div>
      */}
      {/* pricing-area-end */}

      {/* VideoStyleThree area start */}
      <div class="rs-contact-wrap bg7 pt-120 pb-390 md-pt-80">
        <div class="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg white-color"
            subtitle="Contact Sonia"
            titleClass="title white-color"
            title="Vous souhaitez en savoir plus / discuter de nos projets ?"
          />
        </div>
      </div>
      {/* <VideoStyleThree />
       VideoStyleThree area end */}
    </React.Fragment>
  );
};

export default EngagementMain;
