import React, { useState } from "react";
import { Link } from "react-router-dom";

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {
  const [home, setHome] = useState(false);
  const [homeMultipage, setHomeMultipage] = useState(false);
  const [homeOnepage, setHomeOnepage] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setServices] = useState(false);
  const [blog, setBlog] = useState(false);
  const [pages, setPages] = useState(false);
  const [servicePages, setServicePages] = useState(false);
  const [caseStudies, setCaseStudies] = useState(false);
  const [shopPages, setShopPages] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "homeMultipage") {
      setHome(true);
      setHomeMultipage(!homeMultipage);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "homeOnepage") {
      setHome(true);
      setHomeMultipage(false);
      setHomeOnepage(!homeOnepage);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "about") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(!about);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "services") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(!services);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "blog") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(!blog);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "pages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(!pages);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "servicePages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(!servicePages);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "caseStudies") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(false);
      setCaseStudies(!caseStudies);
      setShopPages(false);
    } else if (menu === "shopPages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(!shopPages);
    }
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            {/*<li
              className={
                home
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                onClick={() => {
                  openMobileMenu("home");
                }}
                className={activeMenu === "/" ? "active-menu" : ""}
              >
                Home
              </Link>
              <li className={parentMenu === "culture" ? "current-menu-item" : ""}>
      </li>
			*/}
            {/* <li>
              <Link
                to="/culture"
                className={activeMenu === "/culture" ? "active-menu" : ""}
              >
                Culture
              </Link>
            </li> */}

            {/*
							<ul className={home ? "sub-menu current-menu" : "sub-menu"}>
								<li className={homeMultipage ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
									<Link to="#" onClick={() => { openMobileMenu('homeMultipage'); }}>Multipages</Link>
									<ul className={homeMultipage ? "sub-menu current-menu" : "sub-menu"}>
										<li>
											<Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Main Demo</Link>
										</li>
										<li>
											<Link to="/home-2" className={activeMenu === "/home-2" ? "active-menu" : ""}>Digital Agency 01</Link>
										</li>
										<li>
											<Link to="/home-3" className={activeMenu === "/home-3" ? "active-menu" : ""}>IT Solution 01</Link>
										</li>
										<li>
											<Link to="/home-4" className={activeMenu === "/home-4" ? "active-menu" : ""}>Digital Agency 02</Link>
										</li>
										<li>
											<Link to="/home-5" className={activeMenu === "/home-5" ? "active-menu" : ""}>Software Solution</Link>
										</li>
										<li>
											<Link to="/home-6" className={activeMenu === "/home-6" ? "active-menu" : ""}>Data Analysis</Link>
										</li>
										<li>
											<Link to="/home-7" className={activeMenu === "/home-7" ? "active-menu" : ""}>IT Solution 02</Link>
										</li>
										<li>
											<Link to="/home-8" className={activeMenu === "/home-8" ? "active-menu" : ""}>Gadgets Repairs</Link>
										</li>
										<li>
											<Link to="/home-9" className={activeMenu === "/home-9" ? "active-menu" : ""}>Application Testing</Link>
										</li>
										<li>
											<Link to="/home-10" className={activeMenu === "/home-10" ? "active-menu" : ""}>IT Solution 03</Link>
										</li>
										<li>
											<Link to="/home-11" className={activeMenu === "/home-11" ? "active-menu" : ""}>Digital Agency Dark</Link>
										</li>
										<li>
											<Link to="/home-12" className={activeMenu === "/home-12" ? "active-menu" : ""}>Web Design Agency</Link>
										</li>
										
										<li>
											<Link to="/home-13" className={activeMenu === "/home-13" ? "active-menu" : ""}>Branding Agency</Link>
										</li>
										
									</ul>
								</li>

								<li className={homeOnepage ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
									<Link to="#" onClick={() => { openMobileMenu('homeOnepage'); }}>Onepages</Link>
									<ul className={homeOnepage ? "sub-menu current-menu" : "sub-menu"}>
										<li>
											<Link to="/" className={activeMenu === "/onepage-1" ? "active-menu" : ""}>Main Demo</Link>
										</li>
										<li>
											<Link to="/onepage-2" className={activeMenu === "/onepage-2" ? "active-menu" : ""}>Digital Agency 01</Link>
										</li>
										<li>
											<Link to="/onepage-3" className={activeMenu === "/onepage-3" ? "active-menu" : ""}>IT Solution 01</Link>
										</li>
										<li>
											<Link to="/onepage-4" className={activeMenu === "/onepage-4" ? "active-menu" : ""}>Digital Agency 02</Link>
										</li>
										<li>
											<Link to="/onepage-5" className={activeMenu === "/onepage-5" ? "active-menu" : ""}>Software Solution</Link>
										</li>
										<li>
											<Link to="/onepage-6" className={activeMenu === "/onepage-6" ? "active-menu" : ""}>Data Analysis</Link>
										</li>
										<li>
											<Link to="/onepage-7" className={activeMenu === "/onepage-7" ? "active-menu" : ""}>IT Solution 02</Link>
										</li>
										<li>
											<Link to="/onepage-8" className={activeMenu === "/onepage-8" ? "active-menu" : ""}>Gadgets Repairs</Link>
										</li>
										<li>
											<Link to="/onepage-9" className={activeMenu === "/onepage-9" ? "active-menu" : ""}>Application Testing</Link>
										</li>
										<li>
											<Link to="/onepage-10" className={activeMenu === "/onepage-10" ? "active-menu" : ""}>IT Solution 03</Link>
										</li>
										<li>
											<Link to="/onepage-11" className={activeMenu === "/onepage-11" ? "active-menu" : ""}>Digital Agency Dark</Link>
										</li>
										<li>
											<Link to="/onepage-12" className={activeMenu === "/onepage-12" ? "active-menu" : ""}>Web Design Agency</Link>
										</li>
										<li>
											<Link to="/onepage-13" className={activeMenu === "/onepage-13" ? "active-menu" : ""}>Branding Agency</Link>
										</li>
									</ul>
								</li>
							</ul>

            </li>*/}
            <li>
              <Link
                to="/culture"
                className={activeMenu === "/culture" ? "active-menu" : ""}
              >
                Culture
              </Link>
            </li>
            <li>
              <Link
                to="/expertises"
                className={activeMenu === "/expertises" ? "active-menu" : ""}
              >
                Expertises
              </Link>
            </li>
            <li>
              <Link
                to="/ressources"
                className={activeMenu === "/ressources" ? "active-menu" : ""}
              >
                Ressources
              </Link>
            </li>
            {/* <li className={parentMenu === "nyxo-s-engage" ? "current-menu-item" : ""}>
        <Link
          to="/nyxo-s-engage"
          className={activeMenu === "/nyxo-s-engage" ? "active-menu" : ""}
        >
          Nyxo s'engage
        </Link>
      </li> */}
            <li>
              <Link
                to="/nyxo-s-engage"
                className={activeMenu === "/nyxo-s-engage" ? "active-menu" : ""}
              >
                Nyxo s'engage
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
