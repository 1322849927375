import React, { Component } from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import EngagementMain from "./EngagementMain";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
//import bannerbg from "../../../assets/img/engegement/1.png";
import bannerbg from "../../assets/img/breadcrumbs/1.png";

const Engagement = () => {
  return (
    <React.Fragment>
      {/* Header */}
      <Header parentMenu="nyxo-s-engage" activeMenu="/nyxo-s-engage" />
      {/* Header */}

      <SiteBreadcrumb
        pageTitle="Nyxo s'engage"
        titleClass="page-title"
        pageName="Nyxo s'engage"
        breadcrumbsImg={bannerbg}
      />

      {/* ContactMain Section Start */}
      <EngagementMain />
      {/* ContactMain Section End */}

      {/* Footer */}
      <Footer />
      {/* Footer */}

      {/* SearchModal */}
      <SearchModal />
      {/* SearchModal */}
    </React.Fragment>
  );
};

export default Engagement;
