import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ServiceDetailsContent from "./ServiceDetailsContent";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/3.jpg";

const AnalyticSolutions = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/analytic-solutions"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Analytic Solutions"
        pageCategory="Expertises"
        pageName="Analytic Solutions"
      />
      {/* breadcrumb-area-end */}
      <ServiceDetailsContent />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default AnalyticSolutions;
