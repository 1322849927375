import React from "react";
import SectionTitle from "../Common/SectionTitle";

const ContactInfo = (props) => {
  const { contactBoxClass } = props;
  return (
    <div className={contactBoxClass ? contactBoxClass : "contact-box"}>
      <SectionTitle
        sectionClass="sec-title mb-44"
        subtitleClass="sub-text new-text white-color"
        subtitle="Let's Talk"
        titleClass="title white-color"
        title="Echanger avec nos Experts"
      />
      <div className="address-box mb-24">
        <div className="address-icon">
          <i className="fa fa-phone"></i>
        </div>
        <div className="address-text">
          <span className="label">Téléphone:</span>
          <a href="tel:123222-8888">(+33) 672-541-622</a>
        </div>
      </div>
      <div className="address-box mb-24">
        <div className="address-icon">
          <i className="fa fa-home"></i>
        </div>
        <div className="address-text">
          <span className="label">Email:</span>
          <a href="mailto:rochdi@nyxo.tech">rochdi@nyxo.tech</a>
        </div>
      </div>
      <div className="address-box">
        <div className="address-icon">
          <i className="fa fa-map-marker"></i>
        </div>
        <div className="address-text">
          <span className="label">Adresse:</span>
          <div className="desc">3 Square Henri REGNAULT, Paris La défense</div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
