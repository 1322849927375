import React from "react";
import { Link } from "react-router-dom";

const AppointmentForm = (props) => {
  const { formTitle, formSubTitle } = props;
  return (
    <main>
      {/* <!-- appointment form start --> */}
      <div className="appointment-style white-bg">
        <div className="form-title">
          <h3 className="title">
            {formTitle
              ? formTitle
              : "Nous sommes là pour vous aider, n'hesitez pas à contacter nos experts"}
          </h3>
          <p className="desc">{formSubTitle ? formSubTitle : ""}</p>
        </div>
        <center>
          <div className="btn-part mt-40">
            <Link
              className="readon lets-talk"
              to={{
                pathname: "https://calendly.com/rochdi-berrahmouni/30min",
              }}
              target="_blank"
            >
              Discutons !
            </Link>
          </div>
        </center>
      </div>
      {/* <!-- appointment form end --> */}
    </main>
  );
};

export default AppointmentForm;
