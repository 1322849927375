import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleProcess from "../../components/Common/Process";

const Process = () => {
  return (
    <div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 md-mb-40">
            <div className="process-wrap">
              <SectionTitle
                sectionClass="sec-title mb-30"
                subtitleClass="sub-text new"
                subtitle="Notre Processus"
                titleClass="title white-color"
                title="Notre processus de travail - Comment nous travaillons pour nos clients"
              />
              <div className="btn-part mt-40">
                <Link to="/contact">
                  <a className="readon learn-more contact-us">Contacter nous</a>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-8 pl-30 md-pl-15">
            <div className="row">
              <div className="col-md-6 mb-40">
                <SingleProcess
                  processNumber="1"
                  processTitle="Définir"
                  processText="Identification et définition du besoin ou du problème à traiter"
                />
              </div>
              <div className="col-md-6 mb-40">
                <SingleProcess
                  processNumber="2"
                  processTitle="Mesurer"
                  processText="Collecter les données représentatives, mesurer la performance, identifier les zones de progrès"
                />
              </div>
              <div className="col-md-6 sm-mb-40">
                <SingleProcess
                  processNumber="3"
                  processTitle="Analyser"
                  processText="Une analyse approfondie des données collectées est effectuée pour comprendre les besoins"
                />
              </div>
              <div className="col-md-6">
                <SingleProcess
                  processNumber="4"
                  processTitle="Améliorer"
                  processText="Proposition de solutions pour améliorer le processus via la mise en œuvre de meilleures pratiques, l'optimisation et la simplification du processus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
