import React from "react";

function BlockQuote({}) {
  return (
    <blockquote>
      <p>
        If you are working in an organization that places lots of restrictions
        on how developers can do their work, then microservices may not be for
        you.
        <br />
        <cite>
          {" "}
          Sam Newman, Building Microservices: Designing Fine-Grained Systems
        </cite>
      </p>
    </blockquote>
  );
}

export default BlockQuote;
