import React from "react";

import postImage from "../../assets/img/blog/inner/Microservices-vs-Monolithic-Architecture-Advantages-and-Disadvantages-1.png";

const PostContent = ({}) => {
  return (
    <div className="post-content">
      <div className="bs-img mb-34">
        <img src={postImage} alt="Post Image" />
      </div>
      <div className="blog-full">
        <ul className="single-post-meta">
          <li>
            <span className="p-date">
              <i className="fa fa-calendar"></i>
              January 21, 2023
            </span>
          </li>
          <li>
            <span className="p-date">
              <i className="fa fa-user"></i>
              Admin
            </span>
          </li>
          <li className="Post-cate">
            <div className="tag-line">
              <i className="fa fa-book"></i>
              <a href="#">Software Development</a>
            </div>
          </li>
          <li className="post-comment">
            <i className="fa fa-comments"></i> 1
          </li>
        </ul>
        <p>
          L'API Key et OAuth2 sont tous deux utilisés pour gérer l'accès aux
          API, mais ils diffèrent dans leur mode de fonctionnement et leur
          niveau de sécurité.
        </p>
      </div>
    </div>
  );
};

export default PostContent;
