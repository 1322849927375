import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

import footerLogo1 from "../../../assets/img/logo/logo.png";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                </Link>
              </div>
              <div className="textwidget pb-30">
                <p>
                  Conseil en systèmes et logiciels informatiques :  architecture, transformation digitale, data et intelligence artificielle, assistance
                  opérationnelle, gestion de projets.
                  
                </p>
              </div>
              <ul className="footer-social md-mb-30">
                <li>
                  <a href="https://twitter.com/NyxoTechnology">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/nyxo-solutions">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
              <h3 className="widget-title">Technologies</h3>
              <ul className="site-map">
                {/*                 <li>
                  <Link to="/service/Web-development">Development Web</Link>
                </li>
                <li>
                  <Link to="/service/cloud-and-devOps">Clould & DevOps</Link>
                </li>
                <li>
                  <Link to="/service/product-design">Product & Design</Link>
                </li> */}
                <li>
                  <Link to="#">Development Web</Link>
                </li>
                <li>
                  <Link to="#">Clould & DevOps</Link>
                </li>
                <li>
                  <Link to="#">Product & Design</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
              <h3 className="widget-title">Contact</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                    3 Square Henri REGNAULT, Paris La défense
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <Link to="#">(+33)672-541622</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <Link to="mailto:rochdi@nyxo.tech">rochdi@nyxo.tech</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-clock"></i>
                  <div className="desc">horaire: 10:00 - 18:00</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
