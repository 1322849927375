import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import SinglePricing from "../../components/Pricing/SinglePricing";

// Pricing Image
import silverImg from "../../assets/img/pricing/1.png";
import goldImg from "../../assets/img/pricing/2.png";
import platinumImg from "../../assets/img/pricing/3.png";

const Pricing = () => {
  return (
    <div className="rs-pricing style2 gray-color pt-120 pb-144 md-pt-80 md-pb-80">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44"
          subtitleClass="sub-text style-bg"
          subtitle="Tarifs"
          titleClass="title title2"
          title="Notre plan tarifaire"
        />
        <div className="row">
          <div className="col-lg-4">
            <SinglePricing
              itemClass="pricing-table"
              badge="Portage pour les Freelances"
              pricingImage={silverImg}
              currency=""
              price="5 - 10 %"
              period="de commission sur le TJM"
              buttonClass="readon buy-now"
              buttonText="Contactez nous"
              buttonURL="#"
              // Feature List (1 to 4 item)
              featureText1="Plusieurs missions"
              ability1="yes"
              featureText2="Accès plateforme"
              ability2="yes"
              featureText3="Suivi freelance"
              ability3="yes"
              featureText4="CSM"
              ability4="yes"
            />
          </div>

          <div className="col-lg-4 md-pb-30 md-pt-30">
            <SinglePricing
              itemClass="pricing-table primary-bg"
              badge="Sourcing pour recrutement CDI"
              pricingImage={goldImg}
              currency=""
              price="25 %"
              period="Salaire Brut"
              buttonClass="readon buy-now"
              buttonText="Contactez nous"
              buttonURL="#"
              // Feature List (1 to 4 item)
              featureText1="Renforcer votre équipe interne"
              ability1="yes"
              featureText2="Garantie jusqu’à fin PE"
              ability2="yes"
              featureText3="Profil en 48h"
              ability3="yes"
              featureText4="Accès plateforme CV"
              ability4="yes"
            />
          </div>

          <div className="col-lg-4">
            <SinglePricing
              itemClass="pricing-table"
              badge="Consultant en mode regis"
              pricingImage={platinumImg}
              currency=""
              price="REGIE"
              period="sur devis"
              buttonClass="readon buy-now"
              buttonText="Contactez nous"
              buttonURL="#"
              // Feature List (1 to 4 item)
              featureText1="Expertise"
              ability1="yes"
              featureText2="Tatifs fixes"
              ability2="yes"
              featureText3="Flexibilité "
              ability3="yes"
              featureText4="Suivi consultants"
              ability4="yes"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
