import React from "react";
import Header from "../../components/Layout/Header";
import BlogDetailsApiKeyOAuthMain from "../../components/BlogDetailsApiKeyOAuth";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ScrollToTop from "../../components/Common/ScrollTop";

// Breadcrumbs Background Image
import bannerbg from "../../assets/img/breadcrumbs/4.jpg";

const BlogDetailsApiKeyOAuth = () => {
  return (
    <React.Fragment>
      <Header parentMenu="blog" activeMenu="/blog-details" />

      <SiteBreadcrumb
        pageTitle="La différence entre API Keys et OAuth"
        titleClass="page-title new-title pb-10"
        pageCategory="It Services"
        pageName="La différence entre API Keys et OAuth"
        breadcrumbsImg={bannerbg}
      />

      {/* Blog Main */}
      <BlogDetailsApiKeyOAuthMain />
      {/* Blog Main End */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer />

      <SearchModal />
    </React.Fragment>
  );
};

export default BlogDetailsApiKeyOAuth;
