import SectionTitle from "../../components/Common/SectionTitle";
import SingleService from "../../components/Service/SingleService";

import serviceIcon1 from "../../assets/img/service/icons/1.png";
import serviceIcon2 from "../../assets/img/service/icons/2.png";
import serviceIcon3 from "../../assets/img/service/icons/3.png";
import serviceIcon4 from "../../assets/img/service/icons/4.png";
import serviceIcon5 from "../../assets/img/service/icons/5.png";
import serviceIcon6 from "../../assets/img/service/icons/6.png";

const Service = () => {
  return (
    <div
      id="rs-service"
      className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70"
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-46"
          subtitleClass="sub-text"
          subtitle="Services"
          titleClass="title"
          title="Nous offrons toutes sortes de services de solutions informatiques"
          effectClass="heading-line"
        />
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleService
              itemClass="services-item"
              serviceImage={serviceIcon2}
              Title="Development Web"
              Text="Services de développement d'applications Web et cloud pour augmenter l'évolutivité, l'élasticité et la disponibilité des logiciels."
            />
          </div>
          <div className="col-lg-4 col-md-6 md-mb-20">
            <SingleService
              itemClass="services-item"
              serviceImage={serviceIcon4}
              Title="Clould & DevOps"
              Text="Notre équipe fournit des services d'intégration (CI) et de livraison continues (CD) de logiciels grace à notre maitrise de l'IaC"
            />
          </div>
          <div className="col-lg-4 col-md-6 sm-mb-20">
            <SingleService
              itemClass="services-item"
              serviceImage={serviceIcon5}
              Title="Product & Design"
              Text="Nos experts créent des conceptions UI/UX utiles et utilisables pour les logiciels offrent une meilleure accessibilité."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
