import React from "react";
import PostContent from "./PostContent";
import RecentPostWidget from "../Widget/RecentPostWidget";
import CategoriesWidget from "../Widget/CategoriesWidget";

import imgMiddle from "../../assets/img/blog/inner/monolith-to-microservices.jpg";

const BlogDetailsApiKeyOAuthMain = () => {
  return (
    <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 order-last">
            <div className="widget-area">
              {/* <SearchWidget />
               */}
              <RecentPostWidget />
              <CategoriesWidget />
            </div>
          </div>

          <div className="col-lg-8 pr-34 md-pr-14">
            <div className="blog-details">
              <PostContent />
              {/*<BlockQuote />
               */}
              <div className="blog-full">
                <h3>La différence entre API Keys et OAuth</h3>
                <h4>API Key</h4>
                <p>
                  Une clé API est un code alphanumérique unique qui identifie
                  une application auprès d'une API. Il est utilisé pour
                  authentifier les demandes d'un client à l'API, ainsi que pour
                  limiter les demandes d'un client. Les clés API peuvent être
                  générées pour des applications spécifiques et limitées aux
                  endpoints pour lesquels elles sont autorisées.
                </p>
                <p>
                  Les API Keys sont souvent utilisées comme une méthode simple
                  d'authentification pour les applications qui n'ont pas besoin
                  d'un haut niveau de sécurité. Cependant, les API Keys sont
                  vulnérables aux attaques par ingénierie inverse, car les clés
                  sont stockées et transmises en clair.
                </p>
                <h4>OAuth2</h4>
                <p>
                  OAuth2 est un protocole d'autorisation qui permet aux
                  utilisateurs d'accorder ou de révoquer l'accès aux
                  applications tierces sans divulguer leur mot de passe. OAuth2
                  utilise un jeton d'accès plutôt qu'une clé API pour autoriser
                  les demandes d'API.
                </p>
                <p>
                  Lorsqu'un utilisateur accorde l'accès à une application tierce
                  dans le cadre du flux OAuth2, il est redirigé vers une page de
                  fournisseur de services d'identité (comme Google, Facebook ou
                  LinkedIn) où il se connecte et autorise l'accès à des
                  ressources spécifiques. Le fournisseur d'identité redirige
                  ensuite l'utilisateur vers l'application avec un jeton d'accès
                  qui peut être utilisé pour appeler des API.
                </p>
                <p>
                  L'utilisation de jetons d'accès OAuth2 réduit le risque de vol
                  de données de connexion, car les informations d'identification
                  de l'utilisateur ne sont jamais partagées avec l'application
                  tierce.
                </p>
                <p>
                  En résumé, les clés API sont souvent utilisées pour des
                  applications qui n'ont pas besoin de haut niveau de sécurité,
                  tandis que OAuth2 est utilisé pour des applications qui
                  nécessitent des autorisations d'utilisateurs et une sécurité
                  élevée.
                </p>
                <div className="bs-img mb-30">
                  <img src={imgMiddle} alt="Middle Image" />
                </div>
                {/* 
                <Commenting />
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsApiKeyOAuthMain;
