import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//Custom Components

import Home from "../pages/home";

import About from "../pages/about";
import Team from "../pages/team";
import TeamDetails from "../pages/team/team-details";
import Blog from "../pages/blog";
import BlogDetails from "../pages/blog/blog-details";
import Services from "../pages/service";
import ServiceDetails from "../pages/service/service-details";
import SoftwareDevelopment from "../pages/service/software-development";
import WebDevelopment from "../pages/service/web-development";
import AnalyticSolutions from "../pages/service/analytic-solutions";
import cloudAndDevops from "../pages/service/cloud-and-devops";
import ProductDesign from "../pages/service/project-design";

import Contact from "../pages/contact";
import Engagement from "../pages/engagement";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import BlogDetailsApiKeyOAuth from "../pages/blog/blog-details-api-key-oauth";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/culture" component={About} />
          <Route path="/expertises" exact component={Services} />
          <Route path="/service/service-details" component={ServiceDetails} />
          <Route
            path="/service/software-development"
            component={SoftwareDevelopment}
          />
          <Route path="/service/web-development" component={WebDevelopment} />
          <Route
            path="/service/analytic-solutions"
            component={AnalyticSolutions}
          />
          <Route path="/service/cloud-and-devops" component={cloudAndDevops} />
          <Route path="/service/product-design" component={ProductDesign} />

          <Route path="/team" exact component={Team} />
          <Route path="/team/team-details" component={TeamDetails} />
          <Route path="/ressources" exact component={Blog} />
          <Route path="/blog/blog-details" component={BlogDetails} />
          <Route
            path="/blog/blog-details-api-key-oauth"
            component={BlogDetailsApiKeyOAuth}
          />

          <Route path="/nyxo-s-engage" component={Engagement} />
          <Route path="/contact" component={Contact} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
