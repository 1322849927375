import React from "react";
import OwlCarousel from "react-owl-carousel2";
import SinglePost from "./SinglePost";

import blogImg11 from "../../assets/img/blog/inner/11.jpg";
import blogImg2 from "../../assets/img/blog/inner/2.jpg";
import blogImg3 from "../../assets/img/blog/inner/3.jpg";

const BlogPart = () => {
  const options = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
      },
      992: {
        items: 2,
        stagePadding: 0,
      },
      1200: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      <OwlCarousel options={options}>
        <SinglePost
          blogImage={blogImg11}
          blogCategory="Architecture"
          blogPublishedDate="22 Novembre 2022"
          blogTitle="Refactoriser un monolithe en microservices"
          blogDesc="Migrer une application monolithique vers des microservices à l’aide d’une conception pilotée par domaine..."
          blogButtonClass="blog-button inner-blog"
        />
        <SinglePost
          blogImage={blogImg2}
          blogCategory="Architecture"
          blogPublishedDate="1 Decembre, 2022"
          blogTitle="11 Microservice Design Patterns and Principales"
          blogDesc="API Gateway, Event Sourcing, CQRS, Saga, BFF, Circuit Breaker, Strangler, Datebase per Micoservice, Externalized Configuration, Consumer - Driven Contract Tracing ..."
          blogButtonClass="blog-button inner-blog"
        />
        <SinglePost
          blogImage={blogImg3}
          blogCategory="RGPD"
          blogPublishedDate="1 Decembre 2022"
          blogTitle="Comment Protéger sa vie privée avec des outils livres et opensource"
          blogDesc="..."
          blogButtonClass="blog-button inner-blog"
        />
      </OwlCarousel>
    </React.Fragment>
  );
};

export default BlogPart;
